import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";

const DefaultPageTemplate = ({data}) => {
  const document = data.markdownRemark;
  return (
    <Layout title={document.frontmatter.title}>
      <div className="container pt-12 pb-20 max-w-3xl" dangerouslySetInnerHTML={{ __html: document.html }}/>
    </Layout>
  )
}

export default DefaultPageTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
